















import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';

@Component({
  components: {},
})
export default class ManageContent extends Vue {
  get isModelPics(): boolean {
    return this.$route.name === 'model-pics';
  }

  get videosCount(): number {
    return vxm.model.videosCount;
  }

  created() {
    vxm.user
      .getMe()
      .catch((error) => {
        return error;
      })
      .then(() => {
        vxm.model.setVideoCount(vxm.user.data.stats.totalActiveVideo);
      });
  }
}
